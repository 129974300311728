<template>
  <div v-if="show">
    <div class="modal is-active">
      <div @click="close()" class="modal-background"></div>
      <div class="modal-card has-rounded-corners result-modal">
          <section class="modal-card-body">
            <div class="card-content p-0">
              <div class="container is-size-5 has-text-weight-semibold has-text-centered has-baskerville-font has-text-brand-color mb-2">
                {{ t(`Message from the ${$store.state.uiMods.facilitator}`) }}
              </div>
              <p class="container has-text-centered mb-4" style="white-space: pre-line">
                {{ message }}
              </p>
              <div @click="close()" class="button is-primary-colors has-text-weight-semibold is-fullwidth">
                {{ t('Close') }}
              </div>
            </div>
          </section>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ChatPopUp',
    data () {
      return {
        show: false,
        message: ''
      }
    },
    methods: {
      close() {
        this.message = ''
        this.show = false
        this.$store.commit('updateShowChatAlert', false)
      }
    },
    computed: {
      chats(){
        if(this.$store.state.adventureTeam){
          return this.$store.state.adventureTeam.chats
        } else {
          return null
        }
      }
    },
    watch: {
      chats: {
        handler(newValue, oldValue) {
          if(
            oldValue &&
            newValue &&
            oldValue.length < newValue.length
          ){
            const latestChat = newValue[newValue.length-1]
            if(!latestChat.name && !latestChat.isDoNotInterrupt){
              this.message += latestChat.text + ' '
              this.show = true
            }
          }
        },
        deep: true
      }
    }
  }
  </script>

  <style scoped>
  </style>

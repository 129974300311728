<template>
    <div v-if="show">
      <div class="modal is-active">
        <div @click="close()" class="modal-background"></div>
        <div class="modal-card has-rounded-corners result-modal">
            <section class="modal-card-body">
              <div class="card-content p-0">
                <div class="container is-size-5 has-text-weight-semibold has-text-centered has-baskerville-font has-text-brand-color mb-2">
                  {{ $store.state.uiMods.task }} update
                </div>
                <div class="container has-text-centered mb-4">
                  A {{ $store.state.uiMods.facilitator }} has assessed a {{ $store.state.uiMods.task.toLowerCase() }}!
                </div>
                <div @click="goToTask()" class="button is-primary-colors has-text-weight-semibold is-fullwidth">
                 See result
                </div>
                <div @click="close()" class="container has-text-grey has-text-weight-semibold has-text-centered is-clickable mt-2">
                  {{ t('Close') }}
                </div>
              </div>
            </section>
        </div>
      </div>
    </div>
  </template>

  <script>
  export default {
    name: 'JudgementResultPopUp',
    props: ['task'],
    data () {
      return {
        show: false
      }
    },
    methods: {
      close() {
        this.show = false
      },
      goToTask() {
        this.show = false
        this.$router.push({
          name: 'Challenge',
          params: {
            stageIndex: this.task.stageIndex + 1,
            challengeIndex: this.task.challengeIndex + 1
          }
        })
      }
    },
    watch: {
      task(newTask, oldTask){
        console.log(oldTask)
        console.log(newTask)
        if(!oldTask && newTask){
          this.show = true
        } else if (oldTask && newTask.judgementId !== oldTask.judgementId){
          this.show = true
        }
      }
    }
  }
  </script>

  <style scoped>
  </style>
